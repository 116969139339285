
.service-box{
    width: 100%;
    text-align: center;
    width: 100%;
    padding: 50px 10px 0px 10px;
    letter-spacing: 1px;
    >div{
        display: flex;
        width: 100%;
        justify-content: center;
        >div{
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin: 2rem;
            >.box1{
            
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: 400px;
                margin:0 20px;
                >.text{
                    font-weight: bold;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    text-align:justify;
                    margin: 0 10px;
                    >h3{
                        font-size: 25px;
                        line-height: 28px;
                        text-align: center;
                        cursor: default;
                        font-weight: 600;
                    }
                    >p{
                        padding: 1% ;
                        font-size: 15px;
                        line-height: 24px;
                 
                        text-align: start;
                        font-weight: 400;
                        >button{
                            border: none;
                            background: none;
                            >a{
                             text-decoration: none;
                             color:rgb(48, 114, 220);
                             font-weight: 500;
                                &:hover{
                                    color: black;
                                    text-decoration: underline;
                                    font-weight: 600;
                                }
                            }
                         } 
                    }
                   
                }
            }
        }
    } 
}



@media only screen and (max-width: 1111px) {
    .section1{
        >div{
            >div{
                display: flex;
                flex-direction: column;
                width: 100%;
                >.box1{
                    width: none;
                }
            }
        }
    }
    
}