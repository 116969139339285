.Contact{

    >.contactbox{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        letter-spacing: 1px;
    
        padding: 3rem;
       
     
        >.BOX1{
        
            width: 55%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 20px;
          
           
    
          >.form_div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex:start;
            >input{
                border: 2px solid rgb(226, 222, 222);
                border-radius: 5px;
                padding:  10px;
                margin: 10px 15px;
                width: 15rem;
                font-size: 14px;
            } 
            >div{
                >textarea{
                    width: 100%;
                    border-radius: 5px;
                    padding: 5px;
                    border: 2px solid rgb(226, 222, 222);
                }
            }
          }
    
            >h1{
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 1px;
                text-align: center;
                font-weight: 700;
                padding-bottom: 10px;
            }
            >p{
                padding: 2% 5%;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 1px;
                text-align: center;
                font-weight: 400;
            }
          
        }
        
        >.BOX2{
            background: linear-gradient(138.95deg, #f2f4f9 0, rgba(242, 244, 249, 0) 63.81%);
            width: 35%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 3rem;
           
    
            >h1{
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 1px;
                text-align: center;
                font-weight: 700;
                padding-bottom: 10px;
            }
            >.BOX{
                text-align: center;
                margin: 10px;
                >h2{
                    font-size: 20px;
                 
         
                    text-align: center;
                    font-weight: 600;
               
                }
                >p{
                    padding: 2% 5%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    text-align: center;
                    font-weight: 400;
                }
            }
            
        }

    }
    
}


// MEDIA QUERIES

@media only screen and (max-width: 910px) {
    .Contact{
        padding: 15px;
       
        >.contactbox{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            >.BOX1{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                >.form_div{
                    >input{
                       
                        width: 100%;
                    }
                }
            }
            
            >.BOX2{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
             
            }
        }
 
        
    }

}
