.section {
    width: 100%;
    margin-top: 50px;
    letter-spacing: 1px;
 

    >.container {
        width: 80%;
        padding-bottom: 2rem;

        >h1 {
            font-size: 30px;
            line-height: 28px;
            font-weight: 900;
        
            margin-bottom: 1rem;
            text-align: initial;
          
        }

        >p {
            font-size: 15px;
            line-height: 26px;
       
            font-weight: 500;
            text-align: initial;
            >ul{
                >li{
                    >h4{
                        font-size: 18px !important;
                        width: 100%;
                        font-size: 25px;
                        line-height: 28px;
                        font-weight: 600;
                      
                        margin-bottom: 10px;
                        text-align: initial;
                    }

                }
            }
        }
        
        >h3{
            font-size: 20px !important;
            width: 100%;
            line-height: 28px;
            font-weight: 600;
          
            margin-bottom: 10px;
            text-align: initial;
        }


    }

    >.service-box {
        >div {
            >div {
                width: 80%;
                margin-bottom: 30px;
                >.box1 {
                    width: 100%;
                    margin-left: 0;
                    letter-spacing: 1px;
                    >h1 {
                        text-align: left;
                        width: 100%;
                        font-size: 28px;
                        line-height: 28px;
                        font-weight: 800;
                      
                        margin-bottom: 10px;
                        text-align: initial;
                    }

                    >.text {
                        >h3 {
                            font-size: 23px;
                            line-height: 28px;
                            font-weight: 600;
                          
                            text-align: initial;
                        }

                        >p {
                            font-size: 15px;
                            line-height: 26px;
                           
                            font-weight: 500;
                            text-align: initial;
                            >button{
                                display: block;
                            }
                        }
                    }

                }
            }
        }
    }
}