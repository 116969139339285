
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

*{
    padding: 0%;
    margin: 0%;
    box-sizing:border-box;
}

body{
    background-color: white;
    font-family: "Source Sans 3", sans-serif !important; 
}

html {
    font-family: "Source Sans 3", sans-serif !important; 
  }
  
