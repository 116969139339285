@keyframes imgUp {
    to {
        transform: translateY(-3px);
    }
}


header {
    display: flex;
    flex-direction: column;
  

    >.contact {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        >ol::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0px;
            right: 10px;
            background-image: linear-gradient(to right, #A6C0FE, #F68084);
            transform: skewX(25deg);
        }

        >ol {
            width: 50%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-image: linear-gradient(to right, #A6C0FE, #F68084);
            list-style: none;
            font-size: 14px;
        
            font-weight: 500;
            line-height: 10%;

            >li {
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                justify-content: center;
                z-index: 9999;
                padding: 2px 5px;
                text-decoration: underline;
                
                margin-right: 8px;
                font-weight: 400;
                letter-spacing: 1px;
                >&:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
                >a{
                    text-decoration: none;
                    color: white;
                }
                
               
                svg {
                    padding-top: 5px;
                    font-size: 1.5rem;
                    color: white;
                    
                }
            }
        
            >div {
                display: flex;

                >div {
                    padding: 0px 10px;
                    animation: imgUp 1.2s linear infinite alternate;
                    transition: all 0.5s;
                    svg {
                        padding-top: 5px;
                        font-size: 1.5rem;
                        color: white;
                        
                    }
               
                }

            }
          
        }


    }
}

@media only screen and (max-width: 1040px) {
    header {
        >.contact {
            >ol::before {
                content: none;
            }

            >ol {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 512px) {
    header {
        >.contact {
            >ol::before {
                content: none;
            }
            >ol {
                width: 100% !important;
                padding: 0 !important;
                margin: 0 !important;
                >li{
                    display: flex;
                    flex-direction: column;
                   
                    >svg{
                        display: none;
                    } 
                }
                >div {
                    display: flex;
                    margin-right: 0px !important;
                    padding: 8px 0px 0px 0px !important;
                }
            }
        }
    }
}


@media only screen and (max-width: 435px) {
    header {
        >.contact {
            >ol {
                >li{
                    font-size: 11px;
                }
            }
        }
    }
}

@media only screen and (max-width: 389px) {
    header {
        >.contact {
           display: none;
        }
    }
}