.information{
    width: 100%;

    >.info{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding:30px 0;
        letter-spacing: 1px;
       
        >.title{
            width: 70%;
            padding-left: 10px;
            border-left: 4px solid rgb(236, 236, 236); 
                >p{
                    padding: 2% 5%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    text-align: center;
                    font-weight: 400;
                   
                }
            }
   
        >.association{
            margin-top: 40px;
            width: 70%;
            text-align: justify;
            >h3{
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 1px;
                text-align: center;
                cursor: default;
                font-weight: 600;
            }
            >p{
                padding: 1% 0%;
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
            }
        }
        >.info_list{
            width: 80%;
            display: flex;
            justify-content:center;
            align-items:center;
            border-radius: 5px;
            border: 2px solid rgb(212, 212, 212);
            >.list{
                display: flex;
                margin:5px 20px;
                >p{
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
}


@media only screen and (max-width: 1111px) {
    .information{
        >.info{
            >.info_box{
                display: flex;
                flex-direction: column;
                >.box1{
                    width:100%;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .information{
        >.info{
            >.info_list{
                >.close{
                    display: none;
                }
              
            }
                
            
        }
    }
}