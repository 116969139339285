.home {
  width: 100%;
  height: 80vh;
  display: flex;

  padding-left: 5px;

  > main {
    z-index: -1;
    position: absolute;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 99%;
    height: 80%;
    justify-content: center;

    > .size {
      width: 100%;
      text-align: center;
      height: 100%;

      > .section {
        height: 100%;
        width: 100%;
        margin-top: 0 !important;

        > .mySwiper {
          width: 100%;
          height: 100%;

          > .swiper-wrapper {
            > .swiper-slide-prev {
              > h1 {
                display: none;
              }
            }

            > .swiper-slide-active {
              > div {
                > .top {
                  font-size: 65px !important;
                  animation: captionAnimation1 2s ease-in-out;
                }

                > .bottom {
                  font-size: 65px !important;
                  animation: captionAnimation2 4s ease-in-out;
                }
              }
            }

            > .swiper-slide {
              text-align: center;
              font-size: 18px;
              background: #fff;
              position: relative;
              width: 100%;
              display: flex;
              align-items: center;
              z-index: -1;
              height: 100%;

              > img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                z-index: -1;
              }

              > div {
                width: 75%;
                height: -15%;
                text-align: right;

                > .top {
                  font-size: 55px;
                  line-height: 68px;
                  color: white;

                  font-weight: 750;
                  padding-right: 12%;
                }

                > .bottom {
                  font-size: 45px;
                  color: #00306e;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes captionAnimation1 {
  from {
    opacity: 0;
    transform: translateX(500px);
  }

  to {
    opacity: 1;
  }
}

@keyframes captionAnimation2 {
  from {
    opacity: 0;
    transform: translateY(500px);
  }

  to {
    opacity: 1;
  }
}

.section1 {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  letter-spacing: 1px;

  > .Infobox {
    > h3 {
      font-size: 2.5rem;
    }

    > .Infosec {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      > div {
        > .box1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 350px;
          margin: 5px;

          > .img {
            width: 30%;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
              font-size: 40px;
            }
          }

          > .text {
            font-weight: bold;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;

            > h3 {
              font-size: 20px;
              line-height: 28px;
              text-align: center;
              font-weight: 700;
            }

            > p {
              font-size: 14px;
              line-height: 27px;
              text-align: center;
              color: #00306e;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.section2 {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 80px 0;

  &::before {
    content: "";
    width: 95%;
    height: 75%;
    position: absolute;
    background: no-repeat center/cover url("../Assets/background.webp");
    z-index: -1;
    filter: blur(2px);
    opacity: 0.6;
  }

  > .imagebox {
    width: 50%;

    > img {
      width: 100%;
      object-fit: cover;
    }
  }

  > .textbox {
    width: 50%;

    > div {
      width: 100% !important;
      height: 100%;
      letter-spacing: 1px;
      word-wrap: break-word;

      > h3 {
        font-weight: 500;
        height: 20%;
        display: flex;
        font-size: 1.7rem;

        align-items: center;
        justify-content: center;
        margin-bottom: 0 !important;
      }

      > div {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding: 0 20px;

        > .swiper {
          height: 100%;

          > .swiper-wrapper {
            > .Swiperslide {
              > .box1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                cursor: grab;

                > .img {
                  width: 100%;
                  height: 15%;
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 30%;

                  > svg {
                    font-size: 100px !important;
                  }
                }

                > .text {
                  height: 70%;
                  font-weight: 500;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  text-align: center;

                  > h3 {
                    text-align: center;
                    height: 15%;
                    font-size: 25px;
                    line-height: 28px;
                    font-weight: 700;
                  }

                  > p {
                    font-size: 14px;
                    line-height: 28px;
                    text-align: center;
                    font-family: "Roboto", sans-serif;
                    font-weight: 550;
                    height: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.section3 {
  background-color: white;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  padding: 80px 40px;
  background-color: #ecf4fe;

  > .middle {
    width: 80%;
    background-color: white;
    padding: 2rem 1rem;
    border-radius: 5px;

    > .row {
      width: 100%;
      padding: 0 5px;
      margin: 0 !important;

      > .imgbox {
        padding: 0 !important;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > .text {
        padding: 25px 0px 0px 15px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: justify;

        > h1 {
          font-size: 25px;
          line-height: 28px;
          letter-spacing: 1px;
          text-align: center;
          font-weight: 700;
        }

        > p {
          padding: 2% 5%;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 1px;
          text-align: center;

          font-weight: 400;
        }
      }
    }
  }
}

.section4 {
  width: 100%;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 80px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h1 {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
    font-weight: 700;
    margin: 40px 0;
  }

  > .row {
    width: 100%;

    border-radius: 10px;
    width: 90%;

    > .col {
      padding: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: justify;

      > .smallsize {
        width: 90%;
        margin: 10px;

        background-color: white;

        > .rowbox {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          justify-content: center;
          padding-top: 5px;

          > svg {
            font-size: 3.5rem;
            transition: all 0.3s ease-out;
            color: #00306e;

            &:hover {
              font-size: 4.5rem;
              color: #05336f;
            }
          }

          > h1 {
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: center;
            cursor: default;
            font-weight: 600;
          }
        }

        > p {
          padding: 2% 5%;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 1px;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}

.section5 {
  width: 100%;
  height: 40vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  letter-spacing: 1px;

  > .back-video {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  > .content {
    text-align: center;

    > h1 {
      font-size: 45px;
      color: white;
      font-weight: 600;
    }

    > p {
      font-size: 15px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
      color: white;
    }

    > button {
      text-decoration: none;
      display: inline-block;
      color: white;
      font-size: 13px;
      border: none;
      padding: 10px 20px;
      background-color: rgb(69, 143, 234);
      margin-top: 20px;
      transition: all 0.2s ease-in;

      &:hover {
        background-color: white;
        color: rgb(147, 144, 144);
      }
    }
  }
}

//RESPONSIVE
@media only screen and (max-width: 1110px) {
  .section1 {
    > .Infobox {
      > .Infosec {
        > .box1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 350px;
          height: auto;
          margin: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .home {
    > main {
      > .size {
        > .section {
          > .mySwiper {
            > .swiper-wrapper {
              > .swiper-slide {
                > div {
                  width: 80%;
                  height: -18%;
                  text-align: right;

                  > .top {
                    font-size: 55px !important;
                  }

                  > .bottom {
                    font-size: 50px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    > .Infobox {
      width: 90% !important;

      > h3 {
        font-size: 2rem !important;
      }
    }
  }

  .section2 {
    display: flex;
    flex-direction: column;
    background: no-repeat center/cover url("../Assets/background.webp");
    z-index: -1;

    > .imagebox {
      width: 100%;

      > img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }

    > .textbox {
      width: 100%;
      height: 60vh;

      > div {
        height: 100%;

        > div {
          > .mySwiper {
            > .swiper-wrapper {
              > .Swiperslide {
                > .box1 {
                  > .img {
                    height: 20%;

                    > svg {
                      font-size: 70px !important;
                    }
                  }

                  > .text {
                    height: 80%;

                    > h3 {
                      height: 20%;
                    }

                    > p {
                      height: 40%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .box2 {
    display: flex !important;
    flex-direction: column !important;

    > .divbox {
      width: 100% !important;
      text-align: center !important;
    }
  }

  .row {
    flex-direction: column;
  }

  .section1 {
    > div {
      > div {
        display: flex !important;
        flex-direction: column !important;

        width: 100%;
        > div {
          > .box1 {
            width: none;
          }
        }
      }
    }

    > .Infobox {
      width: 90% !important;

      > h3 {
        font-size: 1.8rem !important;
      }
    }
  }

  .section3 {
    background-color: white;

    > .middle {
      width: 100%;

      > .row {
        > .imgbox {
          > img {
            display: none;
          }
        }
      }
    }
  }

  .section4 {
    > .row {
      > .col {
        > .smallsize {
          > .rowbox {
            height: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .home {
    > main {
      > .size {
        width: 100%;
        margin-right: 0px;
        padding: 6px;

        > .section {
          > .mySwiper {
            > .swiper-wrapper {
              > .swiper-slide {
                > div {
                  text-align: right;

                  > .top {
                    font-size: 45px;
                    font-weight: 600;
                    color: white;
                    line-height: 40px;
                  }

                  > .bottom {
                    font-size: 40px;
                    color: #00306e;
                    line-height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .home {
    > main {
      > .size {
        > .section {
          > .mySwiper {
            > .swiper-wrapper {
              > .swiper-slide {
                > div {
                  width: 90%;
                  height: -18%;
                  text-align: right;

                  > .top {
                    font-size: 45px !important;
                  }

                  > .bottom {
                    font-size: 40px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    > .Infobox {
      width: 90% !important;

      > h3 {
        font-size: 1.5rem !important;
      }

      > .Infosec {
        > .box1 {
          width: 90%;

          > .img {
            > svg {
              font-size: 70px;
            }
          }

          > .text {
            > h3 {
              font-size: 19px;
            }

            > p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .textbox {
    width: 100% !important;
    height: auto !important;
    padding-top: 20px;

    > div {
      > h3 {
        font-size: 1.5rem !important;
      }

      > div {
        > .swiper {
          > .swiper-pagination {
            bottom: 0px !important;
          }

          > .swiper-wrapper {
            > .Swiperslide {
              > .box1 {
                > .img {
                  > svg {
                    font-size: 70px !important;
                  }
                }

                > .text {
                  > h3 {
                    font-size: 19px !important;
                  }

                  > p {
                    letter-spacing: 1px;
                    font-size: 15px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .home {
    > main {
      > .size {
        > .section {
          > .mySwiper {
            > .swiper-wrapper {
              > .swiper-slide {
                > div {
                  width: 90%;
                  height: -18%;
                  text-align: right;

                  > .top {
                    font-size: 40px !important;
                  }

                  > .bottom {
                    font-size: 35px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
