body{
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.footer{
    width: 100%;
    margin-top: auto ;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    background-color: #0f1216;
    padding-top: 40px;
    color: white;
    >#horizontal-line{
        height: 1px;
        width: 80%;
        background-color:#869fb2;
        margin: 20px;
    }
    >.Container{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
     
   
     
        >p{
            font-size: 14px;
            line-height: 26px;
          
            font-weight: 500;
            color: white;
       
        }

        >.container_box{
            width: 50%;
            text-align: justify;
            margin: 10px;
         
            

            >h2{
                font-size: 17px;
                line-height: 28px;
                font-weight: 500;

            }

            >h4{
                font-size: 15px;
                line-height: 27px;
                font-weight: 500;
                margin-bottom: 10px;
               
             
                >li{
                    list-style: none;
                    font-size: 14px;
                    color:  white;
                    cursor:context-menu;
                    font-weight: 400 !important;
                   
                  

                }
            }

            >.grid{
                display: grid;
                grid-template-rows: 4fr 4fr;
                grid-template-columns: 4fr 4fr;

                >p{
                    font-size: 14px !important;
                    
                    >a{
                        color: white;
                        font-size: 14px;
                        text-decoration: none;
                        font-weight:400;
                        transition: all 0.2s ease-in ;
                        &:hover{
                            color: white;
                            text-decoration: underline;
                            font-weight:600;
                        }
                    }
                }
            }
            >.scrollTop{
                svg{
                    display:block;
                    margin-right: -15px;
                    position: fixed;
                    bottom: 35px;
                    right: 40px;
                    background-color: white;
                    color: black;
                    padding: 10px;
                    font-size: 2.7rem;
                    box-shadow: 0px 0px  5px -1px black;
                    border-radius: 20px;
                    transition: all 0.4s ease-in;
                    cursor: pointer;
                    animation: gototop 1.2s linear infinite alternate-reverse;
                    &:hover{
                        color: white;
                        background-color: red;
                       
                    }
                }
              
            }
        }
    } 

}

@keyframes gototop{
    0%{
        transform: translateY(-0.5rem);
    }
    100%{
        transform: translateY(0rem);
    }
}

@media screen and (max-width: 1117px) {
    .footer{
        >.Container{
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 685px) {
    .footer{
        >.Container{
            padding: 10px;
            display: flex;
           
            >.container_box{
                width: 100%;

                >.grid{
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}