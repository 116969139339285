body {
    padding-right: 0;
}

header {
    width: 100%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 25%);
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background-color: white;

    >.container-fluid {
        width: 100%;
        padding: 0 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;

        >.nav-logo {
            width: 9%;
                   

            >a {
                >img {
                  
                    width: 100%;
                }
            }
        }

        >.navitems {
            width: 70%;
            align-items: center;
            justify-content: flex-end;
            letter-spacing: 3px;

            //SERVICES
            >.right-menu {
                padding: 0 5px;

                &:hover {
                    >.dropdown-menu {
                        display: block;
                    }
                }

                //FIRST DROPDOWN
                >.menu-button {
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    font-weight: 580;
                    letter-spacing: 1px;
                    text-align: center;
                    margin-inline: 3%;

                    >.active {
                        color: #DC152A;
                    }

                    >a {
                        display: flex;
                        text-decoration: none;
                        color: black;
                        
                        transition: all 0.3s ease-in;
                        text-transform: uppercase;
                        margin-inline: 3%;
                        letter-spacing: 0px;
                        font-weight: 700;
                        font-size: 15px;

                        &:hover {
                            color: #DC152A;
                            padding: 0 15px;
                        }

                        >svg {
                            padding-bottom: 5px;
                        }
                    }
                }

                >.dropdown-menu {
                    display: none;
                    position: absolute;
                    right: 20%;
                    z-index: 1;
                    border-radius: 0;
                    box-shadow: 4px 5px 10px -5px black;

                    >.grid-container {
                        width: 230px;

                        //SECOND DROPDOWN
                        .grid-item {
                            padding: 10px 0px 10px 10px;
                            text-align: center;
                            border-bottom: 1px solid #73738c;
                            cursor: pointer;
                            color: #73738c;

                            &:hover {
                                >.dropdown-submenu {
                                    display: block;
                                }
                            }

                            >.submenu-button {
                                border: none;
                                background-color: transparent;
                                cursor: pointer;
                                font-weight: 600;
                                font-size: 13px;
                                letter-spacing: 2px;
                                text-align: center;

                                >.active {
                                    color: black;
                                }

                                >.notneeded {
                                    cursor: no-drop;
                                    color: rgb(117, 115, 115);

                                    >svg {
                                        font-size: 13px;
                                    }
                                }


                                >a {
                                    display: flex;
                                    text-decoration: none;
                            
                                    color: rgb(117, 115, 115);
                                    transition: all 0.3s ease-in;

                                    &:hover {
                                        color: black;
                                    }

                                    >svg {
                                        font-size: 13px;
                                    }
                                }
                            }

                            >.dropdown-submenu {
                                margin-top: -28px;
                                left: 100%;
                                display: none;
                                position: absolute;
                                letter-spacing: 2px;
                                padding: 0;
                                width: 200px;
                                background-color: white;
                                box-shadow: 4px 5px 10px -5px black;

                                >.notneeded {
                                    cursor: no-drop;
                                }

                                >li {
                                    padding: 10px 0px 10px 10px;
                                    list-style: none;
                                    text-align: left;
                                    transition: all 0.3s ease-in;
                                    border-bottom: 1px solid#73738c;
                                    font-size: 11px;
                                    font-weight: 500;

                                    >a {
                                        text-decoration: none;
                                        color: #73738c;
                                        transition: all 0.1s ease-in;
                                        font-size: 12px;
                                        font-weight: 600;
      

                                        &:hover {
                                            color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //OTHERS
            >.item {
                text-align: center;
                margin-inline: 3%;
                letter-spacing: 0px;
                transition: all 0.1s ease;
                border: none;
                
                font-weight: 700;
                cursor: pointer;


                >.active {
                    color: #DC152A;
                }

                >a {
                    text-decoration: none;
                    color: black;
                    font-size: 15px;
                    transition: all 0.4s ease-in-out;
                    text-transform: uppercase;


                    &:hover {
                        color: #DC152A;
                        padding: 15px;
                    }
                }
            }
        }






        // RESPONSIVE
        >.modal {
            >.modal-dialog {
                margin: 0;
                width: 430px;

                >.modal-content {
                    top: 0;
                    position: fixed;
                    border-radius: 0;
                    height: 100vh;
                    width: 55vw;
                    background-color: #f3f3f3;
                    border: none;

                    >.modal-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 86%;
                        margin: 0 auto;
                        padding-bottom: 16px;
                        border-bottom: 2px solid black;

                      >h1{
                        display: block !important;
                        >a{
                            text-decoration: none;
                            color: #000;
                            font-size: 18px;
                            font-weight: 800;
                            letter-spacing: 1px;
                        }
                      }

                        svg {
                            color: black;
                            font-size: 25px;
                            font-weight: 800;
                        }

                        >.btn-close {
                            background: transparent;
                        }
                    }

                    >.modal-body {
                        width: 90%;
                        margin: 0 auto;
                        padding: 20px auto;

                        >.modal-line {
                            margin: 5px 0;
                            text-align: justify;
                            cursor: pointer;
                            color: #73738c;

                            &:hover {
                                >.sub-menu-1 {
                                    display: block;
                                }
                            }

                            >.active {
                                color: black;
                            }

                            >.servicestitle {
                                >.active {
                                    color: black;
                                }

                                >a {
                                    transition: all 0.2s ease-in;
                                    
                                    font-size: 14px;
                                    color: #73738c !important;
                                    text-decoration: none;

                                    &:hover {
                                        color: black;
                                        font-size: 16px;
                                    }
                                }

                                svg {
                                    margin-bottom: 5px;
                                }
                            }

                            >a {
                                
                                text-decoration: none;
                                color: #73738c;
                                font-size: 14px;
                                transition: all 0.2s ease-in;

                                &:hover {
                                    color: black;
                                    font-size: 16px;
                                }
                            }


                            >.sub-menu-1 {
                                display: none;

                                >ul {
                                    text-align: justify;
                                    padding: 5px 0;


                                    >li {
                                        list-style: none;


                                        >a {
                                            text-decoration: none;
                                            font-size: 15px;
                                            margin-top: 12px;
                                            transition: all 0.2s ease-in;
                                            color: #73738c;

                                            &:hover {
                                                color: #000;
                                                font-size: 16px;
                                            }
                                        }

                                        &:hover {
                                            >.sub-menu-2 {
                                                display: block;
                                            }
                                        }

                                        >.sub-menu-2 {
                                            display: none;

                                            >ul {
                                                >.notneeded {
                                                    cursor: no-drop !important;
                                                }

                                                >li {
                                                    list-style: none;
                                                    margin-top: 5px;
                                                    text-align: left;

                                                    >a {
                                                        font-size: 14px;
                                                        text-decoration: none;
                                                        color: #73738c;
                                                        transition: all 0.2s ease-in;

                                                        &:hover {
                                                            color: black;
                                                            font-size: 16px;
                                                            font-weight: 800;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


//Responsive
@media(max-width:1114px) {
    header {
        >.container-fluid {
            >.nav-logo {
                width: 140px;
                height: 80px;
                top: -10px;
                left: 20px;

                >a {
                    >img {
                        top: -1px;
                        width: 130px;
                        height: 80px;
                    }
                }
            }
        }
    }

}


@media(max-width:1114px) {
    header {
        >.container-fluid {
            >.nav-logo {
                width: 115px;
                height: 80px;
                top: -10px;
                left: 20px;

                >a {
                    >img {
                        top: -1px;
                        width: 115px;
                        height: 80px;
                    }
                }
            }
        }
    }
}


@media(min-width:995px) {
    .modal-backdrop {
        display: none !important;
    }

    body {
        overflow: auto !important;
    }
}


@media(max-width:512px) {
    header {
        >.container-fluid {
            >.nav-logo {
                width: 80px;
                height: 60px;
                top: 0px;
                left: 0px;

                >a {
                    >img {
                        top: -1px;
                        width: 80px;
                        height: 50px;
                    }
                }
            }
        }
    }
}




@media(max-width:512px) {
    .modal {
        >.modal-dialog {
            >.modal-content {
                top: 32px !important;
                height: 100vh;
                width: 60vw !important;
                background-color: #f3f3f3;
                border: none;
            }
        }
    }
}


@media(max-width:388px) {
    .modal {
        >.modal-dialog {
            >.modal-content {
                top: 0px !important;
                >.modal-header{
                    >h1{
                        >a{
                            line-height: 5px !important;
                            font-size: 15px !important;
                            letter-spacing: 0px !important;
                        }
                    }
                }
            }
        }
    }

}