.section {
    width: 100%;
    margin-top: 50px;
    >.container {
        width: 80%;
        text-align: center;
        padding: 0;

        >h1 {
            font-size: 30px;
            line-height: 28px;
            font-weight: 600;
          
       
        }
    }

    >.service-box {
        >div {
            >div {
                width: 80%;
                margin-bottom: 30px;
                >.box1 {
                    width: 100%;
                    margin-left: 0;
                    >h1 {
                        text-align: left;
                        width: 100%;
                        font-size: 25px;
                        line-height: 28px;
                        font-weight: 600;
                      
                        margin-bottom: 10px;
                    }

                    >.text {
                        >h3 {
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 600;
                        
                        }

                        >p {
                            font-size: 14px;
                            line-height: 26px;
                            
                            font-weight: 500;
                            >button{
                                display: none !important;
                            }
                        }
                    }

                }
            }
        }
    }
}